var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.fetchingOrgUsers
        ? _c(
            "div",
            { staticClass: "d-flex flex-wrap align-center" },
            [
              _vm._l(
                Object.entries(_vm.userCheckboxBinders),
                function (item, index) {
                  return _c("v-checkbox", {
                    key: index,
                    staticClass: "mx-4",
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "label",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                { staticClass: "d-flex align-center" },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-1",
                                      attrs: { small: "", color: "secondary" },
                                    },
                                    [_vm._v(_vm._s(item[1].icon))]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "secondary--text caption text-uppercase",
                                    },
                                    [_vm._v(_vm._s(item[0]))]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: item[1].status,
                      callback: function ($$v) {
                        _vm.$set(item[1], "status", $$v)
                      },
                      expression: "item[1].status",
                    },
                  })
                }
              ),
              _c("v-spacer"),
              _c("v-text-field", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.expand && _vm.orgUsers.length,
                    expression: "expand && orgUsers.length",
                  },
                ],
                staticClass: "px-4",
                attrs: {
                  placeholder: "Search for a member...",
                  autofocus: "",
                  outlined: "",
                  "hide-details": "",
                  dense: "",
                },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on }) {
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  attrs: { text: "", icon: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$store.dispatch(
                                        "orgStore/fetchOrgUsers",
                                        _vm.$route.params.oid
                                      )
                                    },
                                  },
                                },
                                on
                              ),
                              [_c("v-icon", [_vm._v("refresh")])],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1425788027
                  ),
                },
                [_c("span", [_vm._v("refresh")])]
              ),
            ],
            2
          )
        : _vm._e(),
      _c("v-divider", { staticClass: "mb-5" }),
      !_vm.fetchingOrgUsers
        ? _c(
            "div",
            [
              _vm.orgUsers.length
                ? _c("v-data-iterator", {
                    attrs: { items: _vm.filteredOrgUsers, search: _vm.search },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _vm._l(props.items, function (item, index) {
                                return _c(
                                  "div",
                                  { key: item.uid },
                                  [
                                    index > 0
                                      ? _c("v-divider", {
                                          staticClass: "mb-2",
                                          staticStyle: {
                                            opacity: "0.25",
                                            "border-width": "thin",
                                          },
                                          attrs: { inset: "" },
                                        })
                                      : _vm._e(),
                                    _c(
                                      "v-list-item",
                                      { attrs: { "two-line": "" } },
                                      [
                                        _c(
                                          "v-row",
                                          { attrs: { align: "center" } },
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "1" } },
                                              [
                                                _c(
                                                  "v-list-item-avatar",
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          "x-large": "",
                                                        },
                                                      },
                                                      [_vm._v("person")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "3" } },
                                              [
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _c(
                                                      "v-list-item-title",
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.full_name
                                                            ) +
                                                            " "
                                                        ),
                                                        item.is_active
                                                          ? _c(
                                                              "v-chip",
                                                              {
                                                                attrs: {
                                                                  "x-small": "",
                                                                  color:
                                                                    "success",
                                                                },
                                                              },
                                                              [_vm._v("Active")]
                                                            )
                                                          : !item.is_active
                                                          ? _c(
                                                              "v-chip",
                                                              {
                                                                attrs: {
                                                                  "x-small": "",
                                                                  color:
                                                                    "error",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "Inactive"
                                                                ),
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                    _c("v-list-item-subtitle", [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "caption mr-3 font-weight-bold",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(item.email)
                                                          ),
                                                        ]
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "4" } },
                                              [
                                                _c(
                                                  "v-list-item-content",
                                                  [
                                                    _c(
                                                      "v-list-item-subtitle",
                                                      {
                                                        staticStyle: {
                                                          width: "30%",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "ComponentWithStyledBorder",
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "d-flex align-center pa-1",
                                                              },
                                                              [
                                                                item.role ===
                                                                _vm.roleTypes
                                                                  .ORG_MANAGER
                                                                  ? _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "corporate_fare"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : item.role ===
                                                                    _vm
                                                                      .roleTypes
                                                                      .ORG_FACULTY
                                                                  ? _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "supervisor_account"
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _c(
                                                                      "v-icon",
                                                                      [
                                                                        _vm._v(
                                                                          "person"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "ml-1",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.role
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm.isOrgManager
                                          ? _c("v-list-item-action", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex align-center",
                                                },
                                                [
                                                  _c("UserActions", {
                                                    attrs: {
                                                      uid: item.uid.toString(),
                                                      ucid: item.ucid.toString(),
                                                      isActiveUser:
                                                        item.is_active,
                                                      userName: item.full_name,
                                                      hierarchicalLevel:
                                                        _vm.nuvolosObjectTypes
                                                          .ORGANIZATION,
                                                      role: item.role,
                                                      orgName:
                                                        _vm.$store.getters[
                                                          "orgStore/orgLongName"
                                                        ],
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ])
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }),
                              _vm.isOrgManager
                                ? _c(
                                    "div",
                                    [
                                      _c("invite-btn", {
                                        on: {
                                          goToInvite: function ($event) {
                                            return _vm.$emit("goToInvite", {
                                              value: true,
                                            })
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                        {
                          key: "no-results",
                          fn: function () {
                            return [
                              _c(
                                "v-alert",
                                {
                                  staticClass: "mt-3",
                                  attrs: {
                                    value: true,
                                    color: "error",
                                    icon: "warning",
                                    text: "",
                                  },
                                },
                                [_vm._v("Your search for found no results.")]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      770003008
                    ),
                  })
                : _c(
                    "div",
                    [
                      _c(
                        "v-banner",
                        { attrs: { "two-line": "" } },
                        [
                          _c(
                            "v-avatar",
                            {
                              attrs: {
                                slot: "icon",
                                color: "white",
                                size: "60",
                              },
                              slot: "icon",
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    "x-large": "",
                                    icon: "info",
                                    color: "info",
                                  },
                                },
                                [_vm._v("info")]
                              ),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "d-flex flex-column" }, [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "font-weight-bold secondary--text mb-1",
                              },
                              [_vm._v("No users found")]
                            ),
                            _c("span", [
                              _vm._v(
                                " Currently this organization has no additional users. "
                              ),
                              _vm.isOrgManager
                                ? _c(
                                    "span",
                                    [
                                      _vm._v(
                                        " You can add new organization members via the "
                                      ),
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            small: "",
                                            color: "primary",
                                          },
                                        },
                                        [_vm._v("add")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "font-weight-bold secondary--text",
                                        },
                                        [_vm._v("invite")]
                                      ),
                                      _vm._v(" button above. "),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
              _c(
                "v-alert",
                {
                  staticClass: "mt-4",
                  attrs: {
                    value: this.error,
                    color: "error",
                    icon: "warning",
                    text: "",
                  },
                },
                [
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v("An error has occurred"),
                  ]),
                  _c(
                    "div",
                    [
                      _vm._v(
                        " The user could not be revoked. To learn more about user revoke issues, check the troubleshooting documentation "
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "font-weight-bold",
                          attrs: {
                            small: "",
                            outlined: "",
                            text: "",
                            color: "error",
                            href: "https://docs.nuvolos.cloud/faqs/troubleshooting/administration-troubleshooting/i-cant-revoke-a-user-role",
                            target: "_blank",
                          },
                        },
                        [_vm._v(" here ")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        : _c(
            "div",
            [
              _c("v-skeleton-loader", {
                staticClass: "mx-auto",
                attrs: { type: "table" },
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }